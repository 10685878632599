const FOOT_TO_INCHES = 12
const FOOT_TO_CM = 30.48
const INCH_TO_CM = 2.54
const POUND_TO_KILOGRAM = 0.45
const STONE_TO_POUND = 14

// Weight - Imperial
export const kilogramsToPounds = (kilograms) => parseFloat(kilograms) / POUND_TO_KILOGRAM
export const poundsToKilograms = (pounds) => parseFloat(pounds) * POUND_TO_KILOGRAM

// Weight - British Imperial
export const stonePoundsToKilograms = (stone, pounds) => {
  stone = isNaN(stone) ? 0 : parseFloat(stone)
  pounds = isNaN(pounds) ? 0 : parseFloat(pounds)
  const totalPounds = stone * STONE_TO_POUND + pounds

  return poundsToKilograms(totalPounds)
}
export const kilogramsToStonePounds = (kilograms) => {
  const totalPounds = kilogramsToPounds(parseFloat(kilograms))
  const stone = Math.floor(totalPounds / 14)
  const pounds = Math.round(totalPounds % 14)

  return { stone, pounds }
}

// Height - Imperial
export const feetInchesToCm = (ft, inches) => {
  ft = isNaN(ft) ? 0 : parseFloat(ft)
  inches = isNaN(inches) ? 0 : parseFloat(inches)

  return ft * FOOT_TO_CM + inches * INCH_TO_CM
}
export const cmToFeetInches = (cm) => {
  let result = parseFloat(cm) / FOOT_TO_CM
  if (result % 1 >= 0.9583) {
    result = Math.round(result)
  }
  const ft = Math.trunc(result)
  const inches = Math.round((result - ft) * FOOT_TO_INCHES)

  return { ft, in: inches }
}

// NaN Util
export const numOrUndefined = (value) => {
  value = parseFloat(value)

  return value === 0 || isNaN(value) ? undefined : value
}
