import { Checkbox, FormControl, FormControlLabel, Container } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import RemoveLoadingHook from '../../hooks/RemoveLoadingHook'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson, renderContent, subQuestions } from '../../utils/helpers'
import { PersonFieldPaths as paths } from '../../utils/QuestionnaireStateManager'
import CheckBoxList from '../../widgets/CheckBoxList'
import ConditionalSection from '../../widgets/ConditionalSection'
import { SubQuestionLabel } from '../../widgets/FormLabel'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

const Genes = ({ id, geneTests, label }) => {
  const genes = [
    { id: 'brca1', name: 'BRCA1' },
    { id: 'brca2', name: 'BRCA2' },
    { id: 'palb2', name: 'PALB2' },
    { id: 'chek2', name: 'CHEK2' },
    { id: 'atm', name: 'ATM' },
    { id: 'bard1', name: 'BARD1' },
    { id: 'rad51c', name: 'RAD51C' },
    { id: 'rad51d', name: 'RAD51D' },
    { id: 'brip1', name: 'BRIP1' },
  ]

  const { removePersonGeneTests, setPersonGeneTests } = useActions()

  const handleGeneChange = (gene) => () => {
    const geneIndex = geneTests.findIndex(({ nameOfGeneTest }) => gene.id === nameOfGeneTest)

    if (geneIndex >= 0) {
      if (geneTests.length) {
        removePersonGeneTests({ id, type: gene.id })
      }
    } else {
      setPersonGeneTests({ id, type: gene.id })
    }
  }

  return (
    <FormControl key="gtg">
      <SubQuestionLabel>{label}</SubQuestionLabel>
      <CheckBoxList>
        {genes.map((gene) => {
          const isChecked = !!geneTests.find(({ nameOfGeneTest }) => nameOfGeneTest === gene.id)

          return (
            <FormControlLabel
              label={gene.name}
              control={<Checkbox checked={isChecked} onChange={handleGeneChange(gene)} />}
              key={gene.id}
            ></FormControlLabel>
          )
        })}
      </CheckBoxList>
    </FormControl>
  )
}

const GeneticTesting = ({ config: baseConfig }) => {
  const {
    setPersonGeneticTesting,
    removeAllPersonGeneTests,
    removePersonGeneticTesting,
    setPersonGeneticIdentifier,
    removePersonGeneticIdentifier,
  } = useActions()
  const localization = useLocalization()
  const config = subQuestions({ config: baseConfig, key: 'genetic-test-history' })
  const id = useSelector((state) => get(state, ['questionnaire', 'probandId'])) || ''
  const { performed = '', description = '' } =
    useSelector((state) => get(state, [...pathToPerson(id), 'geneticTesting'])) || {}
  const geneTests =
    useSelector((state) => get(state, [...pathToPerson(id), 'cancerRiskData', 'geneTests'])) || []
  const geneticRefNum =
    useSelector((state) => {
      const identifiers = get(state, [...pathToPerson(id), 'identifiers']) || []

      return identifiers.find(({ labelId }) => labelId === 'geneticsRefNum')
    }) || {}

  RemoveLoadingHook()

  return (
    <Container sx={{ gap: 4, pb: 8 }}>
      <ConditionalSection
        label={getLabel(localization, paths.GENETIC_TESTING, 'patient')}
        conditionState={performed}
        onConditionStateChange={(value) => {
          if (value !== performed) {
            setPersonGeneticTesting({ id, type: 'performed', value })
          }
        }}
        cleanUpChildQuestions={() => {
          if (description) {
            removePersonGeneticTesting({ id, type: 'description' })
          }
          if (geneTests.length) {
            removeAllPersonGeneTests({ id })
          }
          if (geneticRefNum) {
            removePersonGeneticIdentifier({ id })
          }
        }}
        showWhenCondition="Y"
        orderSchema={['Y', 'N', 'U']}
      >
        {renderContent({
          renderMap: {
            geneticTestGene: (
              <Genes
                key="gtg"
                id={id}
                geneTests={geneTests}
                label={getLabel(localization, paths.POSITIVE_GENES_LABEL, 'patient')}
              />
            ),
            geneticTestLocation: (
              <TextFieldQuestion
                key="gtl"
                label={getLabel(localization, paths.GENETIC_TESTING_DESCRIPTION, 'patient')}
                value={description}
                handleChange={(value) =>
                  setPersonGeneticTesting({ id, type: 'description', value })
                }
                subQuestion
                fullWidth
              />
            ),
            geneticRefNumber: (
              <TextFieldQuestion
                key="gti"
                label="Genetic reference number (if known)"
                value={geneticRefNum.value || ''}
                handleChange={(value) => setPersonGeneticIdentifier({ id, value })}
                subQuestion
                multiline={false}
              />
            ),
          },
          config,
        })}
      </ConditionalSection>
    </Container>
  )
}

export default GeneticTesting
