import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  OutlinedInput,
  Switch,
  Typography,
} from '@mui/material'
import get from 'lodash/get'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import { useLocalization } from '../redux/reduxHooks'
import { getLabel, pathToPerson } from '../utils/helpers'
import {
  feetInchesToCm,
  cmToFeetInches,
  kilogramsToPounds,
  poundsToKilograms,
  stonePoundsToKilograms,
  kilogramsToStonePounds,
  numOrUndefined,
} from '../utils/measurementUtils'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager'
import FollowUpQuestions from './FollowUpQuestions'
import { SubQuestionLabel } from './FormLabel'

const { HEIGHT, WEIGHT } = PersonFieldPaths

const NumberInput = ({ ...props }) => (
  <OutlinedInput sx={{ maxWidth: '80px' }} type="tel" {...props} />
)

const InputContainer = ({ children, ...props }) => (
  <Box display="flex" alignItems="center" gap={1} {...props}>
    {children}
  </Box>
)

const ImperialWeight = ({ id, setPersonWeight, weight }) => {
  const [isBritishImperial, setIsBritishImperial] = useState(false)
  const imperialWeight = kilogramsToPounds(weight)
  const britishImperialWeight = kilogramsToStonePounds(weight)
  const pounds = isBritishImperial ? britishImperialWeight.pounds : imperialWeight

  return (
    <Box display="flex" flexDirection="column">
      <FormControlLabel
        control={
          <Checkbox
            checked={isBritishImperial}
            onChange={(e) => {
              setIsBritishImperial(e.target.checked)
            }}
          />
        }
        label="British Stone"
      />
      <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
        {isBritishImperial ? (
          <>
            <NumberInput
              value={britishImperialWeight.stone || ''}
              onChange={(e) => {
                setPersonWeight({
                  id,
                  value: numOrUndefined(stonePoundsToKilograms(e.target.value, pounds)),
                })
              }}
              inputProps={{ 'aria-label': 'Person weight in stone, next input for pounds' }}
            />
            <Typography aria-hidden="true">st</Typography>
          </>
        ) : null}
        <NumberInput
          value={Math.round(pounds) || ''}
          onChange={(e) => {
            if (isBritishImperial) {
              setPersonWeight({
                id,
                value: numOrUndefined(
                  stonePoundsToKilograms(britishImperialWeight.stone, e.target.value),
                ),
              })
            } else {
              setPersonWeight({ id, value: numOrUndefined(poundsToKilograms(e.target.value)) })
            }
          }}
          inputProps={{ 'aria-label': 'Person weight in pounds' }}
        />
        <Typography aria-hidden="true">lbs</Typography>
      </Box>
    </Box>
  )
}

const MeasurementsSection = ({ id }) => {
  const { setPersonHeight, setPersonWeight, setIsMetricMeasurementSystem } = useActions()
  const localization = useLocalization()

  const isMetricMeasurementSystem = useSelector(
    (state) => state.questionnaire.isMetricMeasurementSystem,
  )
  const height = useSelector((state) =>
    get(state, [...pathToPerson(id), 'cancerRiskData', 'height']),
  )
  const imperialHeight = cmToFeetInches(height)
  const weight = useSelector((state) =>
    get(state, [...pathToPerson(id), 'cancerRiskData', 'weight']),
  )
  const heightLabel = getLabel(localization, HEIGHT, 'patient')
  const weightLabel = getLabel(localization, WEIGHT, 'patient')

  return (
    <Container sx={{ gap: 1 }}>
      <InputContainer>
        <Typography fontWeight={500}>Measurements</Typography>
        <Box flexGrow={1}></Box>
        <FormControlLabel
          label="Metric"
          control={
            <Switch
              checked={isMetricMeasurementSystem}
              onChange={() => setIsMetricMeasurementSystem()}
              aria-label="Use metric system (uncheck for imperial)"
            />
          }
        />
      </InputContainer>
      <FollowUpQuestions>
        <Container sx={{ gap: 1 }}>
          <SubQuestionLabel>{heightLabel}</SubQuestionLabel>
          <InputContainer>
            {isMetricMeasurementSystem ? (
              <>
                {/* Metric Height */}
                <NumberInput
                  value={Math.round(height) || ''}
                  onChange={(e) => {
                    setPersonHeight({ id, value: numOrUndefined(e.target.value) })
                  }}
                  inputProps={{ 'aria-label': 'Person height in centimeters' }}
                />
                <Typography aria-hidden="true">cm</Typography>
              </>
            ) : (
              <>
                {/* Imperial Height */}
                <NumberInput
                  value={Math.round(imperialHeight.ft) || ''}
                  onChange={(e) => {
                    setPersonHeight({
                      id,
                      value: numOrUndefined(feetInchesToCm(e.target.value, imperialHeight.in)),
                    })
                  }}
                  inputProps={{ 'aria-label': 'Person height in feet, next input for inches' }}
                />
                <Typography aria-hidden="true">feet</Typography>
                <NumberInput
                  value={Math.round(imperialHeight.in) || ''}
                  onChange={(e) => {
                    setPersonHeight({
                      id,
                      value: numOrUndefined(feetInchesToCm(imperialHeight.ft, e.target.value)),
                    })
                  }}
                  inputProps={{ 'aria-label': 'Person height in inches' }}
                />
                <Typography aria-hidden="true">inches</Typography>
              </>
            )}
          </InputContainer>
        </Container>
        <Container sx={{ gap: 1 }}>
          <SubQuestionLabel>{weightLabel}</SubQuestionLabel>
          <InputContainer>
            {isMetricMeasurementSystem ? (
              <>
                {/* Metric Weight */}
                <NumberInput
                  value={Math.round(weight) || ''}
                  onChange={(e) => setPersonWeight({ id, value: numOrUndefined(e.target.value) })}
                  inputProps={{ 'aria-label': 'Person weight in kilograms' }}
                />
                <Typography aria-hidden="true">Kg</Typography>
              </>
            ) : (
              <ImperialWeight id={id} setPersonWeight={setPersonWeight} weight={weight} />
            )}
          </InputContainer>
        </Container>
      </FollowUpQuestions>
    </Container>
  )
}

export default MeasurementsSection
